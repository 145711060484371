import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {escapeRegExp} from 'lodash';

import {ButtonIcon, CustomTable, ProgressIndicator, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {useCertsAppContext} from '../hooks';
import * as messages from '../messages';
import {escrowApi, fileAndDocumentApi} from '../api';
import {CertificationYearSelect, SearchInput} from '../components/shared';
import {Escrow} from '../types';
import {CertificationYearFormFields} from '../types/forms';
import {EscrowStatus} from '../enum';
import {EscrowApplyOnBehalfModal, EscrowApproveModal, EscrowRemoveModal, EscrowReviewModal} from '../components/escrow';

const Escrows = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {showErrorAlert} = useAlerts();
  const {permissions: {isDepartmentalTech}} = useUserContext();
  const {certificationYearsMap, getCertificationYearToDisplay} = useCertsAppContext();
  const selectedCertificationYear = getCertificationYearToDisplay('courses');
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [lastSearchText, setLastSearchText] = useState('');
  const [escrowsData, setEscrowsData] = useState<{escrows: Escrow[], filteredEscrows: Escrow[]}>({
    escrows: [],
    filteredEscrows: []
  });
  const [selectedEscrow, setSelectedEscrow] = useState<Escrow | undefined>(undefined);
  const [showEscrowReviewModal, setShowEscrowReviewModal] = useState(false);
  const [showEscrowApproveModal, setShowEscrowApproveModal] = useState(false);
  const [showEscrowRemoveModal, setShowEscrowRemoveModal] = useState(false);
  const [isApplyOnBehalfModalOpen, setIsApplyOnBehalfModalOpen] = useState(false);

  const getFilteredEscrows = useCallback((escrows: Escrow[], searchText?: string) => {
    return escrows.filter(escrow => {
      if (!searchText) {
        return true;
      } else {
        const searchTermRegexp = RegExp(escapeRegExp(searchText), 'i');
        return searchTermRegexp.test(escrow.name) ||
          searchTermRegexp.test(escrow.email);
      }
    });
  }, []);

  const handleCertificationYearChange = useCallback(async (values: CertificationYearFormFields,
                                                           formikHelpers: FormikHelpers<CertificationYearFormFields>) => {
    setLoadingState(loadingState => ({...loadingState, loading: true}));
    const searchParams = new URLSearchParams();
    searchParams.set('certificationYear', values.certificationYear.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);

    try {
      const escrows = await escrowApi.findAllBy(Number(values.certificationYear));
      setEscrowsData({escrows, filteredEscrows: getFilteredEscrows(escrows)});
      setLoadingState(loadingState => ({...loadingState, loading: false}));
    } catch (error) {
      setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
      showErrorAlert(messages.ESCROWS_LOAD_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    getFilteredEscrows,
    location.pathname,
    navigate,
    showErrorAlert
  ]);

  const handleSearch = useCallback((searchText: string) => {
    setLastSearchText(searchText);
    setEscrowsData({
      ...escrowsData,
      filteredEscrows: getFilteredEscrows(escrowsData.escrows, searchText)
    });
  }, [
    escrowsData,
    getFilteredEscrows
  ]);

  const handleEscrowUpdate = useCallback((updatedEscrow: Escrow) => {
    setEscrowsData(prevEscrowsData => {
      const index = prevEscrowsData.escrows.findIndex(existingEscrow => existingEscrow.id === updatedEscrow.id);
      if (index !== -1) {
        prevEscrowsData.escrows[index] = updatedEscrow;
      } else {
        prevEscrowsData.escrows.push(updatedEscrow);
      }
      return {
        escrows: prevEscrowsData.escrows,
        filteredEscrows: getFilteredEscrows(prevEscrowsData.escrows, lastSearchText)
      };
    });
  }, [
    lastSearchText,
    getFilteredEscrows
  ]);

  const renderEscrowButtonIcon = useMemo(() => (escrow: Escrow) => {
    const escrowDeniedButtonLabelText = `Escrow Denied for ${escrow.name}`;
    const escrowExpiredButtonLabelText = `Escrow Expired for ${escrow.name}`;
    const escrowAcceptedButtonLabelText = `Escrow Accepted for ${escrow.name}`;
    const escrowReviewButtonLabelText = `Review Escrow for ${escrow.name}`;

    switch (escrow.status) {
      case EscrowStatus.PENDING:
        if (!isDepartmentalTech) {
          return (
            <ButtonIcon icon="minus-circle"
                        title={escrowReviewButtonLabelText}
                        ariaLabel={escrowReviewButtonLabelText}
                        className="text-danger"
                        onClick={() => {
                          setSelectedEscrow(escrow);
                          setShowEscrowReviewModal(true);
                        }}/>
          );
        } else {
          return (
            <FontAwesomeIcon icon="minus-circle"
                             title={escrowExpiredButtonLabelText}
                             aria-label={escrowExpiredButtonLabelText}
                             className="text-danger"/>
          );
        }
      case EscrowStatus.APPROVED:
        if (!isDepartmentalTech) {
          return (
            <ButtonIcon icon="check-circle"
                        title={escrowAcceptedButtonLabelText}
                        ariaLabel={escrowAcceptedButtonLabelText}
                        className="text-success"
                        onClick={() => {
                          setSelectedEscrow(escrow);
                          setShowEscrowRemoveModal(true);
                        }}/>
          );
        } else {
          return (
            <FontAwesomeIcon icon="check-circle"
                             title={escrowAcceptedButtonLabelText}
                             aria-label={escrowAcceptedButtonLabelText}
                             className="text-success"/>
          );
        }
      case EscrowStatus.DENIED:
        if (!isDepartmentalTech) {
          return (
            <ButtonIcon icon="times-circle"
                        title={escrowDeniedButtonLabelText}
                        ariaLabel={escrowDeniedButtonLabelText}
                        className="text-danger"
                        onClick={() => {
                          setSelectedEscrow(escrow);
                          setShowEscrowApproveModal(true);
                        }}/>
          );
        } else {
          return (
            <FontAwesomeIcon icon="times-circle"
                             title={escrowDeniedButtonLabelText}
                             aria-label={escrowDeniedButtonLabelText}
                             className="text-danger"/>
          );
        }
      case EscrowStatus.EXPIRED:
        return (
          <FontAwesomeIcon icon="award"
                           title={escrowExpiredButtonLabelText}
                           aria-label={escrowExpiredButtonLabelText}
                           className="text-primary"/>
        );
      default:
        return null;
    }
  }, [
    isDepartmentalTech
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Request Date', sortKey: 'requestDate', className: 'text-nowrap'},
      {title: 'Name', sortKey: 'name', className: 'text-nowrap'},
      {title: 'Email', sortKey: 'email', className: 'text-nowrap'},
      {title: 'Documentation', className: 'text-nowrap text-center'},
      {title: 'Status', className: 'text-nowrap text-center'}
    ],
    className: 'mb-0',
    initialSort: {sortKey: 'requestDate', direction: 'desc' as const},
    items: escrowsData.filteredEscrows,
    noResultsMessage: messages.ESCROWS_NOT_FOUND,
    renderRow: (escrow: Escrow) => {
      const {id, name, email, submissionDateDisplay, certsUserId} = escrow;
      const labelText = `Escrow Documentation for ${name}`;

      return (
        <tr key={id}>
          <td className="text-nowrap align-middle">{submissionDateDisplay}</td>
          <td className="text-nowrap align-middle">{name}</td>
          <td className="text-nowrap align-middle">{email}</td>
          <td className="text-center align-middle">
            <ButtonIcon icon="file-alt"
                        title={labelText}
                        ariaLabel={labelText}
                        onClick={() => fileAndDocumentApi.getEscrowApplication(certsUserId, id)}/>
          </td>
          <td className="text-center align-middle">
            {renderEscrowButtonIcon(escrow)}
          </td>
        </tr>
      );
    }
  }), [
    escrowsData.filteredEscrows,
    renderEscrowButtonIcon
  ]);

  useEffect(() => {
    const loadEscrows = async () => {
      try {
        const escrows = await escrowApi.findAllBy(selectedCertificationYear.value);
        setEscrowsData({escrows, filteredEscrows: [...escrows]});
        setLoadingState(loadingState => ({...loadingState, loading: false}));
      } catch (error) {
        setLoadingState(loadingState => ({...loadingState, loading: false, loadError: true}));
        showErrorAlert(messages.ESCROWS_LOAD_FAILED);
      }
    };
    void loadEscrows();
  }, [
    selectedCertificationYear,
    showErrorAlert
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="Escrows">
        <Row className="d-flex align-items-center">
          <Col md="2">
            <CertificationYearSelect selectedCertificationYear={selectedCertificationYear.value}
                                     certificationYears={certificationYearsMap.courses}
                                     onChange={handleCertificationYearChange}
                                     disabled={loadingState.loading || loadingState.loadError}/>
          </Col>
          <Col md={10} className="d-flex justify-content-end">
            <Button color="primary"
                    onClick={() => setIsApplyOnBehalfModalOpen(true)}>
              Log User into Escrow
            </Button>
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Card>
            <CardHeader className="bg-secondary text-uppercase text-white">Escrows</CardHeader>
            <CardBody>
              <SearchInput onSubmit={handleSearch}
                           disabled={loadingState.loading || loadingState.loadError}/>
              <CustomTable {...tableProps}/>
            </CardBody>
          </Card>
        </>}

        {selectedEscrow && <EscrowReviewModal isOpen={showEscrowReviewModal}
                                              setOpen={setShowEscrowReviewModal}
                                              escrow={selectedEscrow}
                                              onSave={handleEscrowUpdate}/>}

        {selectedEscrow && <EscrowApproveModal isOpen={showEscrowApproveModal}
                                               setOpen={setShowEscrowApproveModal}
                                               escrow={selectedEscrow}
                                               onConfirm={handleEscrowUpdate}/>}

        {selectedEscrow && <EscrowRemoveModal isOpen={showEscrowRemoveModal}
                                              setOpen={setShowEscrowRemoveModal}
                                              escrow={selectedEscrow}
                                              onConfirm={handleEscrowUpdate}/>}

        <EscrowApplyOnBehalfModal isOpen={isApplyOnBehalfModalOpen}
                                  setOpen={setIsApplyOnBehalfModalOpen}
                                  certificationYear={selectedCertificationYear.value}
                                  onSave={handleEscrowUpdate}/>
      </Container>
    );
  }
};

export default Escrows;